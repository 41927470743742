import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./components/Pages/Home/home";

import Footer from "./components/Footer/Footer";
import Bahrain from "./components/Pages/Bahrain/Bahrain";
import Dubai from "./components/Pages/Dubai/Dubai";
import Madagascar from "./components/Pages/Madagascar/Madagascar";
import Monaco from "./components/Pages/Italy/Italy";
import Cyprus from "./components/Pages/Cyprus/Cyprus";
import Greece from "./components/Pages/Greece/Greece";
import Malta from "./components/Pages/Malta/Malta";
import ScrollToTop from "./utils/ScrollToTop";
import Italy from "./components/Pages/Italy/Italy";
import NorthCyprus from "./components/Pages/North Cyprus/NorthCyprus";

function App() {
    return (
        <BrowserRouter>
            <Main />
        </BrowserRouter>
    );
}

function Main() {

    return (
        <>
            <Header />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={
                    <>
                        <Home/>
                    </>
                } />
                <Route path="/bahrain" element={<Bahrain />}/>
                <Route path="/dubai" element={<Dubai/>}/>
                <Route path="/madagascar" element={<Madagascar />}/>
                <Route path="/monaco" element={<Monaco />}/>
                <Route path="/cyprus" element={<Cyprus />}/>
                <Route path="/greece" element={<Greece />}/>
                <Route path="/malta" element={<Malta />}/>
                <Route path="/italy" element={<Italy />}/>
                <Route path="/north-cyprus" element={<NorthCyprus />}/>

            </Routes>
            <Footer />
        </>
    );
}

export default App;