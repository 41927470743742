import React from 'react'
import './Footer.css'


import {
    FaSkype,
    FaFacebook,
    FaLinkedin, FaTelegramPlane,
} from 'react-icons/fa'
import {IoLogoWhatsapp} from "react-icons/io";
import { IoIosCall } from "react-icons/io";

function Footer() {

    const cyprusTelegram = '+35796782975';
    const cyprusTelegramUrl = `https://t.me/${cyprusTelegram}`;

    const cyprusWhatsappUrl = `https://wa.me/${cyprusTelegram.replace('+', '')}`;

    return (
        <footer className='footer'>
            <div className='footer-container container'>
                <a href='tel:+ 355 69 337 7716' className='footer-contact-number'>
                    <IoIosCall className='footer-icon'/>
                    &nbsp;
                    + 355 69 337 7716
                </a>
                <p className='footer-right-pragraph'>
                    &copy; 2024 Nomdom Solution. All rights reserved.
                </p>
            </div>
        </footer>
    )
}

export default Footer
