import React from 'react';
import './Madagascar.css';
import BannerImg from '../../../Assets/img/Madagascar/madagascar-banner.png'
import BannerTaxFree from '../../../Assets/img/Madagascar/masdagascar-tax-free.png';
import BannerBusinessBenefits from '../../../Assets/img/Madagascar/madagascar-business-benefits.png';
import BannerCompanyIncorporation from '../../../Assets/img/Madagascar/madagascar-company-incorporation.png';
import DubaiWhyChoose from '../../../Assets/img/Madagascar/madagascar--why-choose.png';

const Madagascar = () => {

    return (
        <main className=''>
            {/* Add a banner or introductory image */}
            <div className='madagascar-banner-container'>
                <img className='madagascar-banner' src={BannerImg} alt="Monaco Tax Regime Banner"/>
            </div>
            <section className='section'>
                <div className='madagascar-container container'>

                    <div className='madagascar-details'>
                        <h1 className='section-title'>Monaco Tax Regime</h1>
                        <h4 className='section-subtitle'>An Emerging Market with Strategic Business Potential</h4>

                        <p>
                            Monaco, the fourth-largest island in the world, is an emerging market with growing
                            opportunities for investment and business development. While its tax regime is not as widely
                            recognized as those in other global financial hubs, Monaco offers various incentives for
                            businesses, especially those in key sectors like agriculture, mining, and tourism. The
                            country’s strategic location off the southeast coast of Africa provides access to both
                            African and Asian markets, making it a potential gateway for businesses looking to expand in
                            these regions.
                        </p>
                    </div>

                    <div className='madagascar-content'>

                        <div className='madagascar-content-image'>
                            <img src={BannerTaxFree} alt="Tax-Free Benefits for Individuals"
                                 className='madagascar-content-img'/>
                        </div>

                        <div className='madagascar-content-info'>
                            {/* Image relevant to individuals' tax benefits */}
                            <h2 className='section-title-box'>
                                Key Tax Features for Individuals
                            </h2>

                            <ul className='madagascar-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Personal Income Tax
                                    </strong>
                                    Monaco imposes a progressive personal income tax (PIT) on residents, with rates
                                    ranging from 0% to 20%, depending on income levels. The system is designed to be
                                    straightforward, with relatively low rates compared to many other countries.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        Capital Gains Tax
                                    </strong>
                                    Capital gains earned by individuals are generally subject to taxation at a rate of
                                    20%. However, certain exemptions and reductions may apply, particularly for gains
                                    from the sale of shares in companies.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Inheritance Tax
                                    </strong>
                                    Monaco does not impose an inheritance tax, which can be advantageous for estate
                                    planning and wealth transfer within families.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        Social Security Contributions
                                    </strong>
                                    Individuals in Monaco are required to make social security contributions, which are
                                    relatively low compared to global standards. These contributions fund various social
                                    services, including pensions and healthcare.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='madagascar-content'>

                        {/* Image relevant to business benefits */}
                        <div className='madagascar-content-image'>
                            <img src={BannerBusinessBenefits} alt="Tax-Free Benefits for Businesses"
                                 className='madagascar-content-img'/>
                        </div>

                        <div className='madagascar-content-info'>
                            <h2 className='section-title-box'>
                                Business and Incorporation Benefits
                            </h2>
                            <strong className='section-subtitle'>
                                Monaco’s tax regime offers several advantages for businesses, particularly those
                                involved in priority sectors like agriculture, mining, energy, and tourism.
                            </strong>

                            <ul className='madagascar-content-list'>
                                <li><strong className='section-subtitle'>Corporate Income Tax</strong>
                                    The standard corporate income tax (CIT) rate in Monaco is 20%, which is
                                    competitive within the region. For businesses in certain sectors, such as
                                    agriculture and mining, reduced rates or tax holidays may be available.
                                </li>


                                <li><strong className='section-subtitle'>
                                    Tax Incentives for Investment
                                </strong>
                                    Monaco offers various tax incentives to attract foreign investment, particularly
                                    in special economic zones (SEZs). These incentives can include reduced tax rates,
                                    exemptions from certain taxes, and customs duty reductions for imported equipment
                                    and materials.
                                </li>
                                <li><strong className='section-subtitle'>VAT and Customs Duties</strong>
                                    Monaco imposes a Value-Added Tax (VAT) at a standard rate of 20% on most goods
                                    and services. However, businesses operating in certain sectors or SEZs may benefit
                                    from VAT exemptions or reduced rates. Customs duties on imports are also generally
                                    moderate, with specific exemptions available for certain types of goods.
                                </li>
                                <li><strong className='section-subtitle'>No Withholding Tax on Reinvested
                                    Profits</strong>
                                    Profits that are reinvested in the company may be exempt from withholding tax,
                                    encouraging businesses to reinvest earnings into local operations and growth.
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='madagascar-content'>
                        {/* Image relevant to incorporating a company */}
                        <div className='madagascar-content-image'>
                            <img src={BannerCompanyIncorporation} alt="Incorporating a Company in Monaco"
                                 className='madagascar-content-img'/>
                        </div>

                        <div className='madagascar-content-info'>
                            <h2 className='section-title-box'>
                                Incorporating a Company in Monaco
                            </h2>
                            <strong className='section-subtitle'>
                                Incorporating a company in Monaco comes with several potential benefits and
                                opportunities
                            </strong>

                            <ul className='madagascar-content-list'>
                                <li><strong className='section-subtitle'>Strategic Location</strong>
                                    Monaco’s location near key shipping routes between Africa and Asia makes it an
                                    attractive base for companies engaged in trade and export-oriented businesses. The
                                    country’s proximity to major markets in both regions offers significant logistical
                                    advantages.
                                </li>
                                <li><strong className='section-subtitle'>Simplified Incorporation Process</strong>
                                    Monaco has been working to simplify its business registration process, making it
                                    easier for foreign investors to set up companies. The government offers support
                                    through various agencies to assist with the incorporation process and compliance
                                    with local regulations.
                                </li>
                                <li><strong className='section-subtitle'>Access to Natural Resources</strong>
                                    Monaco is rich in natural resources, including minerals, precious stones, and
                                    agricultural products. Businesses involved in extraction, processing, or export of
                                    these resources can benefit from favorable tax treatments and government incentives.
                                </li>
                                <li><strong className='section-subtitle'>Focus on Sustainable Development</strong>
                                    The government of Monaco is increasingly promoting sustainable development,
                                    particularly in sectors like tourism, agriculture, and energy. Companies that align
                                    with these goals may benefit from additional support and incentives.
                                </li>
                                <li><strong className='section-subtitle'>
                                    Growing Market Potential
                                </strong> Monaco offers various incentives
                                    Monaco’s population of over 28 million people presents a growing consumer market
                                    with increasing demand for goods and services. This presents opportunities for
                                    businesses across various sectors, including retail, telecommunications, and
                                    financial services.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='madagascar-container'>
                    <div className='madagascar-box container'>
                        <h1 className='container-title'>
                            Why Choose Monaco?
                        </h1>
                        {/* Image summarizing Italy's benefits */}
                        <div className='madagascar-box-image'>
                            <img src={DubaiWhyChoose} className='madagascar-box-img' alt="Why Choose Monaco"/>
                        </div>

                        <p className='madagascar-box-description'>
                            Monaco offers a unique combination of strategic location, resource wealth, and emerging
                            market potential. While its tax regime is still developing, the country’s efforts to attract
                            foreign investment and its growing economy make it a compelling destination for businesses
                            looking to expand in Africa and beyond. Whether you are involved in agriculture, mining,
                            tourism, or any other sector, Monaco provides a range of opportunities for growth and
                            development in a dynamic environment.
                        </p>

                    </div>
                </div>

            </section>
        </main>
    );
}

export default Madagascar;
