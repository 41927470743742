import React from 'react';
import './Greece.css';
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

import BannerImg from '../../../Assets/img/greece/greece-banner.png'
import BannerTaxFree from '../../../Assets/img/bahrain-tax-free.png'
import BannerBusinessBenefits from '../../../Assets/img/greece/greece-business-benefits.png'
import BannerCompanyIncorporation from '../../../Assets/img/greece/greece-company-incorporation.png'
import BahrainWhyChoose from '../../../Assets/img/greece/greece-why-choose.png'

const Greece = () => {
  
    return (
        <main className=''>
            {/* Add a banner or introductory image */}
            <div className='bahrain-banner-container'>
                <img className='bahrain-banner' src={BannerImg} alt="Monaco Tax Regime Banner"/>
            </div>
            <section className='section'>
                <div className='bahrain-container container'>

                    <div className='bahrain-details'>
                        <h1 className='section-title'>Greece Non-Dom Program</h1>
                        <p>
                            Greece’s Non-Domiciled Resident (Non-Dom) program offers a compelling opportunity for
                            individuals seeking tax efficiency alongside a vibrant lifestyle in one of Europe’s most
                            historic and picturesque countries. Beyond personal tax benefits, Greece is also becoming an
                            increasingly attractive destination for business and investment.
                        </p>
                    </div>

                    <div className='bahrain-details'>
                        <h1 className='section-title'>What is the Non-Dom Program?</h1>
                        <p>
                            The Non-Domiciled Resident (Non-Dom) program in Greece is designed for foreign nationals who
                            reside in Greece but are not domiciled there. This special tax regime allows you to benefit
                            from favorable tax treatment on your global income, provided your domicile remains outside
                            of Greece.
                        </p>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to individuals' tax benefits */}

                        <div className='bahrain-content-image'>
                            <img src={BannerTaxFree} alt="Tax-Free Benefits for Individuals"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>
                            <h2 className='section-title-box'>
                                Key Tax-Free Benefits
                            </h2>

                            <ul className='bahrain-content-list'>
                                <li>
                                    <strong className='section-subtitle'>Tax-Free Foreign Income</strong>
                                    Under the Greek Non-Dom program, you are only taxed on income that is earned within
                                    Greece or remitted to Greece. Any income generated outside Greece that is not
                                    brought into the country remains tax-free.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        No Wealth, Inheritance, or Gift Taxes
                                    </strong>
                                    Greece does not impose wealth taxes. Furthermore, under certain conditions,
                                    inheritance and gift taxes can be minimized or even eliminated, making it an
                                    attractive jurisdiction for wealth preservation and transfer.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Flat Tax on Foreign Income
                                    </strong>
                                    As a Non-Dom, you can opt for a flat annual tax of €100,000 on foreign-sourced
                                    income, regardless of the amount earned. This flat tax also extends to family
                                    members for an additional €20,000 per person, providing a clear and predictable tax
                                    obligation.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to business benefits */}
                        <div className='bahrain-content-image'>
                            <img src={BannerBusinessBenefits} alt="Tax-Free Benefits for Individuals"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>

                            <h2 className='section-title-box'>
                                Business and Incorporation Benefits
                            </h2>

                            <strong className='section-subtitle'>
                                Greece is not just a favorable location for personal tax efficiency; it also offers
                                substantial benefits for entrepreneurs and businesses looking to establish a presence in
                                Europe. Here’s why Greece is an excellent choice for business
                            </strong>

                            <ul className='bahrain-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Attractive Corporate Tax Rate
                                    </strong>
                                    Greece has a competitive corporate tax rate of 22%, which is further enhanced by
                                    incentives for certain types of businesses, such as research and development (R&D)
                                    companies. Additionally, Greece offers special regimes for shipping companies and
                                    other industries.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Strategic Access to EU and Global Markets
                                    </strong>
                                    Greece’s strategic location at the crossroads of Europe, Asia, and Africa makes it
                                    an ideal hub for businesses targeting multiple regions. As an EU member, companies
                                    incorporated in Greece benefit from access to the European Union’s single market.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Incentives for Investment
                                    </strong>
                                    Greece has introduced various incentives to attract foreign investment, including
                                    tax exemptions, subsidies, and fast-track procedures for large-scale projects. These
                                    incentives are particularly appealing for investors in sectors such as tourism,
                                    energy, and technology.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        No Capital Gains Tax on Foreign Income
                                    </strong>
                                    For Non-Doms, capital gains from the disposal of assets outside Greece are not
                                    subject to Greek tax, even if these gains are remitted to Greece. This provides
                                    significant opportunities for tax-efficient wealth management.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Growing Startup Ecosystem
                                    </strong>
                                    Greece is rapidly emerging as a startup-friendly environment, with numerous
                                    incubators, accelerators, and government initiatives designed to support innovation
                                    and entrepreneurship. The combination of low operating costs and a skilled workforce
                                    makes Greece an attractive destination for tech companies and startups.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to incorporating a company */}
                        <div className='bahrain-content-image'>
                            <img src={BannerCompanyIncorporation} alt="Incorporating a Company in Monaco"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>

                            <h2 className='section-title-box'>
                                Incorporating a Company in Greece
                            </h2>

                            <strong className='section-subtitle'>
                                Setting up a business in Greece is increasingly streamlined and comes with several
                                advantages
                            </strong>

                            <ul className='bahrain-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Simplified Incorporation Process
                                    </strong>
                                    The process of incorporating a company in Greece has been simplified, with many
                                    procedures available online. This makes it easier and quicker for entrepreneurs to
                                    establish a business.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Diverse Business Structures
                                    </strong>
                                    Greece offers a variety of company structures, including Single-Member Private
                                    Companies (IKE), Limited Liability Companies (EPE), and Societas Europaea (SE). This
                                    flexibility allows businesses to choose the structure that best suits their needs.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Tax Reliefs for New Enterprises
                                    </strong>
                                    New companies in Greece can benefit from reduced tax rates and reliefs, especially
                                    those involved in innovation, technology, and green energy sectors. These incentives
                                    are designed to stimulate economic growth and attract foreign capital.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Access to EU Funding
                                    </strong>
                                    Companies in Greece can access a variety of EU funding programs aimed at supporting
                                    business development, research, and innovation. This financial support can be a
                                    significant boost for growing enterprises.
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className='bahrain-container'>
                    <div className='bahrain-box container'>
                        <h1 className='container-title'>
                            Why Greece?
                        </h1>
                        {/* Image summarizing Italy's benefits */}
                        <div className='bahrain-box-image'>
                            <img src={BahrainWhyChoose} className='bahrain-box-img' alt="Why Choose Monaco"/>
                        </div>
                        <p className='bahrain-box-description'>
                            Greece combines the appeal of a favorable tax regime with a dynamic business environment.
                            With its strategic location, growing economy, and rich cultural heritage, Greece offers a
                            unique opportunity for both living and doing business. Whether you are an individual seeking
                            tax efficiency or an entrepreneur looking to expand into new markets, Greece provides the
                            ideal conditions for success.
                        </p>
                    </div>
                </div>

            </section>
        </main>
    );
}

export default Greece;
