import React from 'react';
import './Malta.css';
import BannerImg from '../../../Assets/img/malta/malta-banner.png'
import BannerTaxFree from '../../../Assets/img/malta/malta-tax-free.png';
import BannerBusinessBenefits from '../../../Assets/img/malta/malta-business-benefits.png';
import BannerCompanyIncorporation from '../../../Assets/img/malta/malta-company-incorporation.png';
import MaltaWhyChoose from '../../../Assets/img/malta/malta-why-choose.png';

const Malta  = () => {

    return (
        <main className=''>
            {/* Add a banner or introductory image */}
            <div className='malta-banner-container'>
                <img className='malta-banner' src={BannerImg} alt="Malta Tax Regime Banner"/>
            </div>
            <section className='section'>
                <div className='malta-container container'>

                    <div className='malta-details'>
                        <h1 className='section-title'>Malta Non-Dom Program</h1>
                        <h4 className='section-subtitle'>Tax-Free Residency and Business Opportunities</h4>

                        <p>
                            Malta’s Non-Domiciled Resident (Non-Dom) program is not just a tax-efficient residency
                            option; it’s also a gateway to significant business opportunities. For individuals and
                            entrepreneurs looking to optimize their tax obligations while running or incorporating a
                            business, Malta offers unparalleled advantages.
                        </p>
                    </div>

                    <div className='malta-details'>
                        <h1 className='section-title'>What is the Non-Dom Program?</h1>
                        <p>
                            The Non-Domiciled Resident (Non-Dom) program in Malta allows individuals who reside in Malta
                            but are not domiciled there to enjoy substantial tax benefits. If you live in Malta but have
                            your permanent home ("domicile") elsewhere, this program is designed to minimize your tax
                            burden.
                        </p>
                    </div>

                    <div className='malta-content'>
                        {/* Image relevant to individuals' tax benefits */}
                        <h2 className='section-title-box'>
                            Key Tax-Free Benefits
                        </h2>

                        <div className='malta-content-image'>
                            <img src={BannerTaxFree} alt="Tax-Free Benefits for Individuals"
                                 className='malta-content-img'/>
                        </div>

                        <div className='malta-content-info'>
                            <ul className='malta-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Tax-Free Foreign Income
                                    </strong>
                                    As a Non-Dom resident in Malta, you are only taxed on the income that is earned
                                    within Malta or remitted to Malta. Foreign income that remains outside Malta is
                                    entirely tax-free.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Tax on Foreign Capital Gains
                                    </strong>
                                    Any capital gains arising outside Malta are not taxed, even if remitted to the
                                    country. This provides significant tax planning opportunities for high-net-worth
                                    individuals and international investors.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Wealth, Inheritance, or Gift Taxes
                                    </strong>
                                    Malta does not impose wealth taxes, inheritance taxes, or gift taxes. This makes it
                                    an attractive jurisdiction for preserving and passing on wealth.
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='malta-content'>
                        <h2 className='section-title-box'>
                            Business and Incorporation Benefits
                        </h2>

                        {/* Image relevant to business benefits */}
                        <div className='malta-content-image'>
                            <img src={BannerBusinessBenefits} alt="Tax-Free Benefits for Businesses"
                                 className='malta-content-img'/>
                        </div>

                        <div className='malta-content-info'>

                            <strong className='section-subtitle'>
                                Malta is not just a favorable tax jurisdiction for individuals—it is also a highly
                                advantageous location for businesses and entrepreneurs. Here’s why
                            </strong>

                            <ul className='malta-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Competitive Corporate Tax Rate
                                    </strong>
                                    Malta’s effective corporate tax rate can be as low as 5% after applying Malta’s full
                                    imputation system and tax refunds available to shareholders. This makes Malta one of
                                    the most competitive jurisdictions in the EU for corporate taxation.
                                </li>


                                <li>
                                    <strong className='section-subtitle'>
                                        Access to the EU Market
                                    </strong>
                                    Incorporating a company in Malta provides access to the European Union’s single
                                    market, allowing businesses to operate freely across all EU member states. This is
                                    particularly beneficial for companies looking to expand their operations within
                                    Europe.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        Double Taxation Relief
                                    </strong>
                                    Malta has an extensive network of double taxation treaties with over 70 countries.
                                    This ensures that income is not taxed twice, providing further tax efficiency for
                                    international businesses and investors.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        Stable Business Environment
                                    </strong>
                                    Malta offers a stable political and economic environment with a robust legal system,
                                    making it a secure location for business operations. The country’s business-friendly
                                    policies and strong regulatory framework are designed to support growth and
                                    innovation.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        Skilled Workforce
                                    </strong>
                                    Malta boasts a highly educated and multilingual workforce, with English being one of
                                    the official languages. This facilitates smooth business operations and
                                    communications within and outside Malta.
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='malta-content'>
                        <h2 className='section-title-box'>
                            Incorporating a Company in Malta
                        </h2>

                        {/* Image relevant to incorporating a company */}
                        <div className='malta-content-image'>
                            <img src={BannerCompanyIncorporation} alt="Incorporating a Company in Malta"
                                 className='malta-content-img'/>
                        </div>

                        <div className='malta-content-info'>
                            <strong className='section-subtitle'>
                                Setting up a company in Malta is straightforward and comes with significant advantages
                            </strong>

                            <ul className='malta-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Quick and Efficient Process
                                    </strong>
                                    The process of incorporating a company in Malta is quick and streamlined. The
                                    regulatory environment is designed to encourage business formation and growth.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>Flexible Company Structures</strong>
                                    Malta offers a variety of company structures, including Limited Liability Companies
                                    (LLCs), Public Limited Companies (PLCs), and International Holding Companies (IHCs),
                                    providing flexibility to meet your specific business needs.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>Access to EU and Global Markets</strong>
                                    By incorporating in Malta, businesses gain access not only to the EU market but also
                                    to global markets through Malta’s strategic location in the Mediterranean.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>Attractive for Holding Companies</strong>
                                    Malta is a prime location for setting up holding companies due to its favorable tax
                                    regime and the absence of capital gains tax on the disposal of shares by
                                    non-residents.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='malta-container'>
                    <div className='malta-box container'>
                        <h1 className='container-title'>
                            Why Malta?
                        </h1>
                        {/* Image summarizing Malta's benefits */}
                        <div className='malta-box-image'>
                            <img src={MaltaWhyChoose} className='malta-box-img' alt="Why Choose Malta"/>
                        </div>

                        <p className='malta-box-description'>
                            Malta combines a tax-efficient environment with a high quality of life, making it an ideal
                            destination for both living and doing business. Its strategic location, excellent
                            infrastructure, and favorable tax regime position Malta as a leading hub for international
                            business and investment.
                        </p>
                        <p className='malta-box-description'>
                            This enhanced explanation provides a comprehensive overview of the benefits of the Non-Dom
                            Program, as well as the advantages of running and incorporating a business in Malta, making
                            it highly appealing to potential clients interested in both personal and corporate tax
                            efficiency.
                        </p>

                    </div>
                </div>

            </section>
        </main>
    );
}

export default Malta;
