import React, { useState, useEffect, useCallback, useRef } from 'react';

import { RiMenuLine, RiCloseLine, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { TbGridDots } from "react-icons/tb";
import { FaExternalLinkAlt } from "react-icons/fa";


import './Header.css';
import WhiteColorLogo from '../../Assets/img/Header/white-color-logo.png';
import BlackColorLogo from '../../Assets/img/Header/black-color-logo.png';

import { Link } from 'react-router-dom';

const Navbar = () => {
    // Right Menu
    const [RightMenuOpen, setRightMenuOpen] = useState(false);
    const [headerClass, setHeaderClass] = useState(''); // New state to track header class

    // Left Menu
    const [leftMenuOpen, setLeftMenuOpen] = useState(false);

    const [isToggleClicked, setIsToggleClicked] = useState(false);
    const headerRef = useRef(null);

    // Toggle Right Menu
    const toggleRightMenu = useCallback(() => {
        setIsToggleClicked(true);
        setRightMenuOpen(prevMenuOpen => {
            if (!prevMenuOpen) setLeftMenuOpen(false); // Close left menu if opening right menu
            return !prevMenuOpen;
        });
    }, []);

    // Toggle Left Menu
    const toggleLeftMenu = useCallback(() => {
        setIsToggleClicked(true);
        setLeftMenuOpen(prevMenuOpen => {
            if (!prevMenuOpen) setRightMenuOpen(false); // Close right menu if opening left menu
            return !prevMenuOpen;
        });
    }, []);
    

    const closeDropdownsAndMenu = useCallback(() => {
        setRightMenuOpen(false);
        setLeftMenuOpen(false);
    }, []);

    const scrollToTopAndCloseMenu = () => {
        window.scrollTo(0, 0);
        closeDropdownsAndMenu();
    };

    const handleClickOutside = useCallback((event) => {
        if (isToggleClicked) {
            setIsToggleClicked(false);
            return;
        }
        if (headerRef.current && !headerRef.current.contains(event.target)) {
            closeDropdownsAndMenu();
        }
    }, [closeDropdownsAndMenu, isToggleClicked]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1118) {
                setRightMenuOpen(false);
                setLeftMenuOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    const [logoSrc, setLogoSrc] = useState(WhiteColorLogo); // Default logo image

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setHeaderClass('header--white'); // Apply white background when scrolled beyond 50px
                setLogoSrc(BlackColorLogo); // Change to black logo
            } else {
                setHeaderClass('');
                setLogoSrc(WhiteColorLogo); // Revert to original logo
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll); // Clean up event listener
        };
    }, []);



    return (
        <header className={`header ${headerClass}`} ref={headerRef}>
            <nav className="nav container">
                <div
                    className={`nav-toggle-container ${(leftMenuOpen || RightMenuOpen) ? 'menu-open' : ''}`}
                    onClick={toggleLeftMenu}
                    aria-expanded={leftMenuOpen}
                >
                    {leftMenuOpen ? (
                        <>
                            <RiCloseLine className="nav__toggle-menu"/> <span>Close</span>
                        </>
                    ) : (
                        <>
                            <TbGridDots className="nav__toggle-menu"/> <span>Expertise</span>
                        </>
                    )}
                </div>
                
                <Link to='/' className="nav__logo" 
                      onClick={scrollToTopAndCloseMenu}
                >
                    <img src={logoSrc} alt='header logo' className='header-logo-img'/>
                </Link>

                <div
                    className={`nav-toggle-container ${(leftMenuOpen || RightMenuOpen) ? 'menu-open' : ''}`}
                    onClick={toggleRightMenu}
                    aria-expanded={RightMenuOpen}
                >
                    {RightMenuOpen ? (
                        <>
                            <RiCloseLine className="nav__toggle-menu"/> <span>Close</span>
                        </>
                    ) : (
                        <>
                            <RiMenuLine className="nav__toggle-menu"/> <span>Menu</span>
                        </>
                    )}
                </div>

                <div className={`nav__menu  ${leftMenuOpen ? 'show-menu' : ''}`} id="nav-menu">
                    <ul className="nav__list container">
                        <div className='nav-list-group'>
                            <li>
                                <Link to='/bahrain' className='nav__link' onClick={scrollToTopAndCloseMenu}>
                                    Bahrain
                                    <FaExternalLinkAlt className='nav-link-icon' />
                                </Link>
                            </li>
                            <li>
                                <Link to='/dubai' className='nav__link' onClick={scrollToTopAndCloseMenu}>
                                    Dubai
                                    <FaExternalLinkAlt className='nav-link-icon' />
                                </Link>
                            </li>
                        </div>

                        <div className='nav-list-group'>
                            <li>
                                <Link to='/madagascar' className='nav__link' onClick={scrollToTopAndCloseMenu}>
                                    Madagascar
                                    <FaExternalLinkAlt className='nav-link-icon' />
                                </Link>
                            </li>
                            <li>
                                <Link to='/monaco' className='nav__link' onClick={scrollToTopAndCloseMenu}>
                                    Monaco
                                    <FaExternalLinkAlt className='nav-link-icon' />
                                </Link>
                            </li>
                        </div>
                    </ul>
                </div>

                <div className={`nav__menu  ${RightMenuOpen ? 'show-menu' : ''}`} id="nav-menu">
                    <ul className="right-nav__list container">
                        <li>
                            <Link to='/cyprus' className='nav__link ' onClick={scrollToTopAndCloseMenu}>
                                Cyprus
                                <FaExternalLinkAlt className='nav-link-icon'/>
                            </Link>
                        </li>
                        <li>
                            <Link to='/greece' className='nav__link ' onClick={scrollToTopAndCloseMenu}>
                                Greece
                                <FaExternalLinkAlt className='nav-link-icon'/>
                            </Link>
                        </li>
                        <li>
                            <Link to='/malta' className='nav__link' onClick={scrollToTopAndCloseMenu}>
                                Malta
                                <FaExternalLinkAlt className='nav-link-icon'/>
                            </Link>
                        </li>
                        <li>
                            <Link to='/italy' className='nav__link' onClick={scrollToTopAndCloseMenu}>
                                Italy
                                <FaExternalLinkAlt className='nav-link-icon'/>
                            </Link>
                        </li>
                        <li>
                            <Link to='/north-cyprus' className='nav__link' onClick={scrollToTopAndCloseMenu}>
                                North Cyprus
                                <FaExternalLinkAlt className='nav-link-icon'/>
                            </Link>
                        </li>
                    </ul>
                </div>

            </nav>
        </header>
    );
};

export default Navbar;