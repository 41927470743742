import React from 'react';
import './Cyprus.css';
import BannerImg from '../../../Assets/img/Cyprus/cyprus-banner.png'
import BannerTaxFree from '../../../Assets/img/Cyprus/cyprus-tax-free.png';
import BannerBusinessBenefits from '../../../Assets/img/Cyprus/cuprus-business-benefits.png';
import BannerCompanyIncorporation from '../../../Assets/img/Cyprus/cyprus-company-incorporation.png';
import DubaiWhyChoose from '../../../Assets/img/Cyprus/cyprus-why-choose.png';

const Cyprus = () => {

    return (
        <main className=''>
            {/* Add a banner or introductory image */}
            <div className='cyprus-banner-container'>
                <img className='cyprus-banner' src={BannerImg} alt="Monaco Tax Regime Banner"/>
            </div>
            <section className='section'>
                <div className='cyprus-container container'>

                    <div className='cyprus-details'>
                        <h1 className='section-title'>Cyprus Non-Dom Program</h1>
                        <h4 className='section-subtitle'>Tax-Free Residency and Business Opportunities</h4>

                        <p>
                            Cyprus offers one of the most attractive Non-Domiciled Resident (Non-Dom) programs in
                            Europe, combining tax efficiency with a strategic location at the crossroads of Europe,
                            Asia, and Africa. For individuals and businesses alike, Cyprus provides a wealth of
                            opportunities to optimize tax obligations while benefiting from a stable and
                            business-friendly environment.
                        </p>
                    </div>

                    <div className='cyprus-details'>
                        <h1 className='section-title'>What is the Non-Dom Program?</h1>

                        <p>
                            The Non-Domiciled Resident (Non-Dom) program in Cyprus is designed for individuals who
                            reside in Cyprus but are not domiciled there. This special tax status allows you to enjoy
                            significant tax benefits on your global income, making Cyprus an ideal location for
                            expatriates, investors, and entrepreneurs.
                        </p>
                    </div>

                    <div className='cyprus-content'>

                        <div className='cyprus-content-image'>
                            <img src={BannerTaxFree} alt="Tax-Free Benefits for Individuals"
                                 className='cyprus-content-img'/>
                        </div>

                        <div className='cyprus-content-info'>
                            {/* Image relevant to individuals' tax benefits */}
                            <h2 className='section-title-box'>
                                Key Tax-Free Benefits
                            </h2>

                            <ul className='cyprus-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Tax-Free Foreign Income and Dividends
                                    </strong>
                                    Under the Cyprus Non-Dom program, you are not taxed on income earned outside Cyprus,
                                    regardless of whether it is remitted to Cyprus or not. Additionally, dividends,
                                    interest, and capital gains on foreign investments are completely tax-free.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Inheritance, Wealth, or Gift Taxes
                                    </strong>
                                    Cyprus does not impose inheritance, wealth, or gift taxes, allowing you to preserve
                                    and pass on wealth efficiently.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Special Defense Contribution (SDC)
                                    </strong>
                                    As a Non-Dom, you are exempt from the Special Defense Contribution, a tax levied on
                                    dividends, interest, and rental income for Cypriot residents. This makes Cyprus one
                                    of the most tax-friendly jurisdictions for Non-Doms in Europe.
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className='cyprus-content'>

                        {/* Image relevant to business benefits */}
                        <div className='cyprus-content-image'>
                            <img src={BannerBusinessBenefits} alt="Tax-Free Benefits for Businesses"
                                 className='cyprus-content-img'/>
                        </div>

                        <div className='cyprus-content-info'>
                            <h2 className='section-title-box'>
                                Business and Incorporation Benefits
                            </h2>
                            <strong className='section-subtitle'>
                                Cyprus is not only a tax haven for individuals but also an advantageous location for
                                businesses. Here’s why Cyprus stands out as a prime destination for entrepreneurs and
                                companies
                            </strong>

                            <ul className='cyprus-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Low Corporate Tax Rate
                                    </strong>
                                    Cyprus boasts one of the lowest corporate tax rates in the European Union at just
                                    12.5%. This low tax rate, combined with other incentives, makes Cyprus highly
                                    attractive for companies seeking to minimize their tax liabilities.
                                </li>


                                <li><strong className='section-subtitle'>
                                    Extensive Double Taxation Treaty Network
                                </strong>
                                    Cyprus has signed double taxation treaties with more than 65 countries, ensuring
                                    that income is not taxed twice. This extensive network enhances tax efficiency for
                                    international businesses and investors.
                                </li>
                                <li><strong className='section-subtitle'>EU and International Market Access</strong>
                                    As a member of the European Union, companies incorporated in Cyprus benefit from
                                    access to the EU’s single market. Additionally, Cyprus’s strategic location provides
                                    a gateway to markets in the Middle East, North Africa, and beyond.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        Tax Incentives for New Businesses
                                    </strong>
                                    Cyprus offers various tax incentives to attract foreign direct investment, including
                                    tax exemptions for innovative startups, R&D incentives, and allowances for
                                    intellectual property (IP) income. These incentives make Cyprus a hub for innovation
                                    and entrepreneurship.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        No Withholding Tax on Dividends
                                    </strong>
                                    Cyprus does not levy withholding tax on dividend payments to non-residents. This is
                                    particularly advantageous for holding companies and investors looking to repatriate
                                    profits without incurring additional taxes.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='cyprus-content'>
                        {/* Image relevant to incorporating a company */}
                        <div className='cyprus-content-image'>
                            <img src={BannerCompanyIncorporation} alt="Incorporating a Company in Monaco"
                                 className='cyprus-content-img'/>
                        </div>

                        <div className='cyprus-content-info'>
                            <h2 className='section-title-box'>
                                Incorporating a Company in Cyprus
                            </h2>
                            <strong className='section-subtitle'>
                                Incorporating a company in Cyprus is straightforward and comes with numerous benefits
                            </strong>

                            <ul className='cyprus-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Efficient Incorporation Process
                                    </strong>
                                    The process of setting up a company in Cyprus is streamlined, with clear guidelines
                                    and minimal bureaucratic hurdles. This allows businesses to establish their presence
                                    quickly and start operations without delay.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        Flexible Company Structures
                                    </strong>
                                    Cyprus offers a variety of company structures, including Limited Liability Companies
                                    (LLCs), Public Limited Companies (PLCs), and International Business Companies
                                    (IBCs). This flexibility allows businesses to choose the most suitable structure for
                                    their operations.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        IP Box Regime
                                    </strong>
                                    Cyprus’s IP Box regime offers one of the most favorable tax treatments for income
                                    derived from intellectual property in the EU, with effective tax rates as low as
                                    2.5%. This is a significant advantage for companies involved in technology,
                                    pharmaceuticals, and other IP-intensive industries.
                                </li>
                                <li>
                                    <strong className='section-subtitle'>
                                        Stable and Transparent Legal System
                                    </strong>
                                    Cyprus has a stable and transparent legal system based on English common law, which
                                    is highly regarded internationally. This provides a secure environment for business
                                    operations and protects investors’ rights.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='cyprus-container'>
                    <div className='cyprus-box container'>
                        <h1 className='container-title'>
                            Why Choose Cyprus?
                        </h1>
                        {/* Image summarizing Italy's benefits */}
                        <div className='cyprus-box-image'>
                            <img src={DubaiWhyChoose} className='cyprus-box-img' alt="Why Choose Monaco"/>
                        </div>

                        <p className='cyprus-box-description'>
                            Cyprus offers a unique combination of tax efficiency, strategic location, and a high
                            standard of living, making it an ideal destination for both individuals and businesses.
                            Whether you are looking to optimize your personal tax situation or expand your business into
                            new markets, Cyprus provides the perfect environment for success.
                        </p>

                    </div>
                </div>

            </section>
        </main>
    );
}

export default Cyprus;
