import React from 'react';
import './NorthCyprus.css';
import BannerImg from '../../../Assets/img/North Cyprus/north-cyprus-banner.png'
import BannerTaxFree from '../../../Assets/img/North Cyprus/tax-free.png'
import BannerBusinessBenefits from '../../../Assets/img/North Cyprus/business-benefits.png'
import BannerCompanyIncorporation from '../../../Assets/img/North Cyprus/company-incorporation.png'
import WhyChoose from '../../../Assets/img/North Cyprus/north-cyprus-why-choose.png'

const NorthCyprus = () => {
    
    return (
        <main className=''>
            {/* Add a banner or introductory image */}
            <div className='bahrain-banner-container'>
                <img className='bahrain-banner' src={BannerImg} alt="Monaco Tax Regime Banner"/>
            </div>
            <section className='section'>
                <div className='bahrain-container container'>

                    <div className='bahrain-details'>
                        <h1 className='section-title'>North Cyprus Non-Dom Program</h1>
                        <h3 className='section-subtitle'>Tax-Free Residency and Business Advantages</h3>
                        <p>
                            North Cyprus presents an appealing Non-Domiciled Resident (Non-Dom) program, providing tax-efficient residency and business opportunities at the crossroads of Europe, the Middle East, and Asia. For individuals and businesses seeking to optimize their tax obligations while enjoying a welcoming and secure environment, North Cyprus offers a range of benefits, making it an ideal location for expatriates, entrepreneurs, and investors.
                        </p>
                    </div>

                    <div className='bahrain-details'>
                        <h3 className='section-subtitle'>What is the Non-Dom Program in North Cyprus?</h3>
                        <p>
                            The Non-Domiciled Resident (Non-Dom) program in North Cyprus is designed for individuals who reside in the country but are not domiciled there. This special status allows you to access significant tax advantages on global income, transforming North Cyprus into an attractive base for international individuals and businesses.
                        </p>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to individuals' tax benefits */}

                        <div className='bahrain-content-image'>
                            <img src={BannerTaxFree} alt="Tax-Free Benefits for Individuals"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>
                            <h2 className='section-title-box'>
                                Tax-Free Benefits for Individuals
                            </h2>

                            <ul className='bahrain-content-list'>
                                <li>
                                    <strong className='section-subtitle'>Tax-Free Foreign Income and Dividends</strong>
                                    As a Non-Dom in North Cyprus, your foreign-earned income is not subject to local taxation, regardless of whether it is remitted to North Cyprus. Additionally, dividends, interest, and capital gains on foreign investments are entirely tax-free.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        No Inheritance, Wealth, or Gift Taxes
                                    </strong>
                                    North Cyprus does not impose any taxes on inheritance, wealth, or gifts, ensuring that your wealth can be preserved and passed on without additional tax burdens.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        No Special Defense Contribution (SDC)
                                    </strong>
                                    Non-Doms are exempt from the Special Defense Contribution, which is levied on dividends, interest, and rental income for residents. This makes North Cyprus a favorable tax jurisdiction for expatriates and investors.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to business benefits */}
                        <div className='bahrain-content-image'>
                            <img src={BannerBusinessBenefits} alt="Tax-Free Benefits for Individuals"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>

                            <h2 className='section-title-box'>
                                Tax-Free Benefits for Businesses
                            </h2>

                            <strong className='section-subtitle'>
                                Business and Incorporation Advantages
                            </strong>

                            <ul className='bahrain-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Low Corporate Tax Rate
                                    </strong>
                                    North Cyprus offers a low corporate tax rate of 10%, making it a competitive destination for businesses looking to reduce their tax liabilities.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Double Taxation Treaties
                                    </strong>
                                    While not as extensive as some other countries, North Cyprus has a growing network of double taxation agreements, ensuring that income is not taxed twice, enhancing tax efficiency for businesses operating internationally.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Strategic Location for International Markets
                                    </strong>
                                    Situated between Europe, the Middle East, and Asia, North Cyprus serves as a gateway to multiple markets. Companies incorporated here can benefit from access to these regions, making it an attractive hub for trade and international business.
                                </li>


                            </ul>
                        </div>
                    </div>

                    <div className='bahrain-content'>
                        {/* Image relevant to incorporating a company */}
                        <div className='bahrain-content-image'>
                            <img src={BannerCompanyIncorporation} alt="Incorporating a Company in Monaco"
                                 className='bahrain-content-img'/>
                        </div>

                        <div className='bahrain-content-info'>

                            <h2 className='section-title-box'>
                                Incorporating a Company in North Cyprus
                            </h2>

                            <strong >
                                Setting up a company in North Cyprus is a streamlined process with several advantages
                            </strong>

                            <ul className='bahrain-content-list'>
                                <li>
                                    <strong className='section-subtitle'>
                                        Efficient Incorporation Process
                                    </strong>
                                    Incorporating a company in North Cyprus is straightforward, with a well-organized system that minimizes bureaucratic hurdles, allowing businesses to establish quickly.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        Flexible Company Structures
                                    </strong>
                                    North Cyprus offers a variety of company structures, such as Limited Liability Companies (LLCs), and International Business Companies (IBCs), providing businesses with the flexibility to choose the structure that best suits their operations.
                                </li>

                                <li>
                                    <strong className='section-subtitle'>
                                        No Withholding Tax on Dividends for Non-Residents
                                    </strong>
                                    North Cyprus does not impose withholding tax on dividend payments made to non-residents, offering further tax advantages for holding companies and investors.
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className='bahrain-container'>
                    <div className='bahrain-box container'>
                        <h1 className='container-title'>
                            Why Choose North Cyprus?
                        </h1>
                        {/* Image summarizing Italy's benefits */}
                        <div className='bahrain-box-image'>
                            <img src={WhyChoose} className='bahrain-box-img' alt="Why Choose Monaco"/>
                        </div>
                        <p className='bahrain-box-description'>
                            North Cyprus provides a unique combination of tax efficiency, strategic location, and a favorable business environment. Its Non-Dom program allows both individuals and businesses to benefit from low taxes while enjoying a Mediterranean lifestyle in a stable, welcoming jurisdiction. For anyone looking to optimize their tax situation or expand their business across new markets, North Cyprus is an ideal choice.
                        </p>
                    </div>
                </div>

            </section>
        </main>
    );
}

export default NorthCyprus;
